.buttons {
    display: flex;
    justify-content: center;
    gap: 1rem;
    flex-wrap: wrap;

    @media screen and (max-width: 350px){
    }
    button {
            color: #000;
            background: #fff;
            padding: 0.65rem 1rem;
            border-radius: 10px;
            font-size: 0.85rem;
            text-transform: capitalize;
            cursor: pointer;
            border: none;
            margin-top: 1rem;
            font-family: "Poppins";
            
    
            &.active {
                background: #E1094A;
                color: #fff;
            }
        }
    }
    .workImages {
        margin-top: 5rem;
        display: grid;
        grid-template-columns: repeat(3,1fr);
        gap: 1rem;
        text-align: center;
        
        @media screen and (max-width: 800px) {
            grid-template-columns: repeat(2,1fr);
        }
        
        @media screen and (max-width: 600px) {
            grid-template-columns: 1fr;
        }

        .thumbnail {
            position: relative;
            display: inline-block;
        }

        .caption {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate( -50%, -50% );
            text-align: center;
            color: #E1094A;
            font-size: 1.8rem;
            text-shadow: 0 0 3px #000000, 0 0 8px #E1094A;
        }

        .workImage {
            max-width: 1080px;
            max-height: 1350px;
            margin-left: auto;
            margin-right: auto;
            position: relative;

            @media screen and (max-width:600px) {
                max-width: 350px;
                justify-self: center;

            }

            img {
                width: 100%;
                height: 100%;
                object-fit: contain;
                display: block;
            }

            .hoverLayer {
                background: #E1094A;
                width: 100%;
                height: 100%;
                z-index: 5;
                position: absolute;
                top: 0;
                left: 0;
                display: flex;
                gap: 1.5rem;
                justify-content: center;
                align-items: center;

                a {
                    width: 40px;
                    height: 40px;
                    background: rgba(255,255,255,0.25);
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    border-radius: 50%;

                    @media screen and (max-width: 844px){
                        width: 350px;
                        height: 444px;
                    }

                    svg {
                        color: #fff;
                        font-size: 1.5rem;
                    }
                }
            }
        }
}

.talk {
    margin-top: 3rem;
    background: #fff;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1rem;
    border-radius: 5px;
    max-width: 700px;
    margin-left: auto;
    margin-right: auto;
    flex-wrap: wrap;
    gap: 1rem;

    .talk_left{

        h3 {
            font-size: 1.5rem;

            span {
                font-size: 3.5rem;
                color: #E1094A;

                @media screen and (max-width: 599px) {
                    font-size: 2rem;
                }

                @media screen and (max-width: 360px) {
                    font-size: 1.5rem;
                }
            }
        }
    }
    .talk_right {
        align-self: flex-end;
        margin-bottom: 1.5rem;
        background: #E1094A;
        padding: 0.65rem 1rem;
        border-radius: 10px;
        font-size: 0.85rem;
         
        a {
            text-decoration: none;
            color: #fff;
            text-transform: capitalize;
        }
    }
}