* {
    padding: 0;
    margin: 0;
    box-sizing: border-box;
    scroll-behavior: smooth;
}
body {
    font-family: "Poppins", sans-serif;
    font-weight: 500;
    overflow: visible;
    
    background-image: url('./assets/home-bg.jpg');
    background-size: auto;
    background-position: top right 25%;
    background-repeat: no-repeat;
    background-color: #131212;
    height: 100vh;
}

    body::-webkit-scrollbar {
    width: 1px;               /* width of the entire scrollbar */
    }
  
    body::-webkit-scrollbar-track {
    background: #131212;        /* color of the tracking area */
    }
  
    body::-webkit-scrollbar-thumb {
    background-color: #131212;    /* color of the scroll thumb */
    border-radius: 20px;       /* roundness of the scroll thumb */
    border: 1px solid #131212;  /* creates padding around scroll thumb */
    }

img {
    width: 100%;
}

.container {
    max-width:1224px;
    min-height: 100vh;
    padding: 7rem 1.5rem 0 1.5rem;
    margin: 0 auto;
}
@font-face {
    font-family: "Poppins";
    src: @import url('https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800&display=swap');
}
.title {
    text-align: center;
    margin-bottom: 3rem;
}
.title span {
    color: #E1094A;
    text-transform: uppercase;
    font-size: 0.75rem;
    font-weight: 800;
    letter-spacing: 5px;
}
.title h1 {
    color: #fff;
    font-size: 3rem;
    position: relative;
}
@media screen and (max-width: 500px) {
    .container {
        padding: 7rem 1rem 0 1rem;
    }
    .title h1 {
        font-size: 2.5rem;
    }

    body {
        background-position: right 23% bottom 20%;;
    }
}
